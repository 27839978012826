import Layout from 'components/Layout'
import React, { useState } from 'react'
import { Col, Row, Section } from '../components/grid'
import { StaticImage } from 'gatsby-plugin-image'
import { Button } from '@arckit/ui'
import { Link } from 'gatsby'
import { H1, H2, H3 } from 'components/typography/Headings'
import { P } from 'components/typography/Paragraph'
import GetStartedCTA from 'components/forms/GetStartedCTA'
import JoinOurWebinars from 'components/sections/JoinOurWebinars'
import ImpactStats from 'components/sections/ImpactStats'
import OverlayVideo from 'components/overlay-video'
import Modal from 'components/global/Modal'
import FAQ, { IQandA } from 'components/global/FAQ'
import ReviewBadges2 from 'components/state-reviews/ReviewBadges2'
import DigitalSamplesCTA from 'components/cta/DigitalSamples'
import Resource from 'components/global/Resource'
import GetStartedRainbowCTA from 'components/cta/GetStartedRainbow'
import CTAGrid from 'components/forms/CTAGrid'

const faqs: IQandA[] = [
	{
		id: '1',
		question: 'Do ARC solutions align to the science of reading? ',
		answer: [
			<span key="answer-1-1" className="mb-3 block">
				Yes, all ARC solutions align to the science of reading. Resources provide explicit and systematic instruction in foundational reading skills, including print
				concepts, phonological awareness, advanced phonemic awareness, letter formation, phonics, word recognition, word study, spelling, morphology, and fluency. Products
				also intentionally build knowledge with content-rich texts and daily writing to practice skills and support learning. For more information, look here:
			</span>,
			<span key="answer-1-2">
				<Resource title="Grade K | Foundational Skills Scope & Sequence" linkOnly>
					Foundational Skills Scope & Sequence
				</Resource>
			</span>
		]
	},
	{
		id: '2',
		question: 'Does ARC offer Spanish literacy solutions?',
		answer: [
			<span key="answer-2-1" className="mb-3 block">
				Yes, <Link to="/arc-core/?lang=sp">ARC Core en español</Link> is available for grades K–5. ARC also offers the Evaluación del nivel independiente de lectura
				<sup>®</sup> (ENIL<sup>®</sup>), a developmental reading taxonomy for students. The ENIL parallels the Independent Reading Level Assessment (IRLA) while reflecting
				how the stages of learning to read differ between Spanish and English.
			</span>,
			<span key="answer-2-2" className="block">
				ARC <Link to="/intervention/#enil-toolkits">ENIL Foundational Skills Toolkits</Link> are intervention resources designed to support students that demonstrate a need
				for specific skills in reading in Spanish, grades K–2. Additionally, ARC boasts a 150,000-title database that includes both{' '}
				<Link to="/libraries/#collections">English and Spanish texts</Link>.
			</span>
		]
	},
	{
		id: '3',
		question: 'What interventions does ARC offer for students not yet reading at grade level?',
		answer: [
			<span key="answer-3-1">
				ARC has many <Link to="/intervention">resources</Link> designed to support both elementary and secondary students that need intervention, small-group instruction,
				or high-dosage tutoring. These resources range from support for early learners through explicit skills instruction in phonemic awareness, phonics,
				encoding/decoding, word study, fluency, and spelling to support for secondary students through high-interest texts that build knowledge and reading skills.
			</span>
		]
	},
	{
		id: '4',
		question: "How does ARC's curriculum build knowledge?",
		answer: [
			<span key="answer-4-1" className="mb-3 block">
				ARC Core builds knowledge across four units throughout the school year. Students dig deeply into Science content like Marine Life, Social Studies content like U.S.
				States, and literature content like Traditional Tales. Students read, write, research, and analyze these topics through the course of each unit. They learn new
				information, develop vocabulary, and build expertise in a wide variety of disciplines. For instance, in the Animal Adaptations unit, students delve into specific
				adaptations of their chosen animals, supported by a wealth of related books.
			</span>,
			<span key="answer-4-2" className="block">
				ARC Core has earned recognition by the{' '}
				<a href="https://knowledgematterscampaign.org/curriculum/arc-core/" target="_blank">
					Knowledge Matters Campaign
				</a>{' '}
				as one of the few ELA curricula that build knowledge.
			</span>
		]
	},
	{
		id: '5',
		question: ' Does ARC Core teach writing? ',
		answer: [
			<span key="answer-5-1">
				ARC Core is a writing-first curriculum. Students write every day in multiple forms for different audiences and on many topics. Tasks include on-demand writing in
				response to texts and extended writing pieces derived from deep research using high-quality texts. Check out some of our{' '}
				<Link to="/student-books">student authors</Link>!
			</span>
		]
	}
]

export default function HomePage() {
	const [modalOpen, setModalOpen] = useState(false)

	function goToCta() {
		const input = document.getElementById('get-started-cta-email')
		input?.focus()
	}

	return (
		<Layout pageName="Home">
			<Section className="overflow-hidden pt-10 pb-0">
				<div className="absolute top-0 h-full w-full bg-white">
					<div className="rainbow-gradient-light absolute right-0 bottom-0 top-auto h-[250px] w-[250px] rounded-[30%_70%_71%_29%_/_30%_30%_70%_70%] opacity-50 blur-[60px] sm:block md:bottom-auto md:top-8 md:h-[500px] md:w-[500px]"></div>
					<div className="rainbow-gradient-light absolute left-0 right-auto -top-[110px] hidden h-[300px] w-[300px] -translate-x-[50%] translate-y-[20%] rounded-full opacity-50 blur-[80px] sm:block"></div>
				</div>

				<Row className="relative">
					<Col width="w-full md:w-1/2" className="self-center">
						<H1 className="mb-4">
							Setting the Standard for{' '}
							<span className="relative leading-relaxed text-ab-100 after:absolute after:-bottom-8 after:left-0 after:h-8 after:w-full after:rounded-[50%] after:border-[5px] after:border-transparent after:border-t-core-y sm:leading-normal">
								High-Quality
							</span>{' '}
							<span className="whitespace-nowrap">K–12</span> Curriculum
						</H1>
						<P className="mb-8">
							ARC creates structured literacy solutions in service of our mission to ensure that every child in America is reading and writing on or above grade
							level.
						</P>
						<div className="mb-8 flex items-center gap-6">
							<Button onClick={goToCta} size="xl">
								Get Started
							</Button>
							<button onClick={() => setModalOpen(true)} className="flex gap-2">
								<span className="text-gray-500">Watch It in Action</span>
								<div className="flex h-6 w-6 items-center justify-center rounded-full border border-ab-100">
									<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="h-3 w-3 text-ab-100">
										<path
											fillRule="evenodd"
											d="M4.5 5.653c0-1.426 1.529-2.33 2.779-1.643l11.54 6.348c1.295.712 1.295 2.573 0 3.285L7.28 19.991c-1.25.687-2.779-.217-2.779-1.643V5.653z"
											clipRule="evenodd"
										/>
									</svg>
								</div>
							</button>
						</div>
					</Col>
					<Col width="w-1/2" className="hidden self-end md:block">
						<StaticImage loading="eager" src="../../static/images/home/hero2.png" alt="girl reading roberto clemente book" />
					</Col>
				</Row>
			</Section>
			<Section flatGray>
				<Row className="mb-10 items-end pt-20 text-center sm:text-left">
					<Col width="w-full sm:w-1/2" className="mb-4 sm:mb-0">
						<H2>
							Research-Backed <br /> Literacy Solutions
						</H2>
					</Col>
					<Col width="w-full sm:w-1/2">
						<P>
							Unlock the potential of <span className="font-semibold italic text-gray-700">every</span> student with our full range of K–12 curriculum solutions.
						</P>
					</Col>
				</Row>
				<Row className="pb-20">
					<Col width="w-full sm:w-1/2" className="mb-6 sm:mb-0">
						<Link to="/arc-core" className="hover:no-underline">
							<div className="mb-6 rounded-lg border-t-4 border-t-ab-100 bg-white shadow-lg">
								<div className="p-8">
									<H3 className="mb-3">Core Curriculum</H3>
									<P>
										Grounded in the science of reading, and with foundational skills and knowledge-building at its heart, ARC Core<sup>®</sup> meets the English
										and Spanish literacy needs of all students in any learning environment. <span className="text-ab-100">Learn More</span>
									</P>
								</div>
								<StaticImage src="../../static/images/home/core-curriculum-2.png" alt="arc core logo" />
							</div>
						</Link>
						<Link to="/digital-solutions" className="hover:no-underline">
							<div className="rounded-lg border-t-4 border-t-core-y bg-white p-8 shadow-lg">
								<H3 className="mb-3">Digital Solutions</H3>
								<P>
									Powerful dashboards and reports assess student performance in real time at the classroom, school, and district levels and allow schools to use
									equity-driven data to transform instruction and outcomes. <span className="text-ab-100">Learn More</span>
								</P>
							</div>
						</Link>
					</Col>
					<Col width="w-full sm:w-1/2">
						<Row>
							<Col width="w-full xl:w-1/2" className="mb-6 pl-0 pr-0 xl:pl-0 xl:pr-4">
								<div className="rounded-lg border-t-4 border-t-core-r bg-white p-8 shadow-lg">
									<Link to="/100-book-challenge" className="hover:no-underline">
										<H3 className="mb-3">Supplemental Solutions</H3>
										<P size="md">
											100 Book Challenge<sup>®</sup> accelerates learning in any core program by adding daily opportunities to transfer taught skills to
											high-interest texts. <span className="text-ab-100">Learn More</span>
										</P>
									</Link>
								</div>
							</Col>
							<Col width="w-full xl:w-1/2" className="mb-6 pl-0 pr-0 xl:pr-0 xl:pl-4">
								<div className="rounded-lg border-t-4 border-t-core-g bg-white p-8 shadow-lg">
									<Link to="/formative-assessment" className="hover:no-underline">
										<H3 className="mb-3">Formative Assessment</H3>
										<P size="md">
											The IRLA<sup>®</sup> is a game-changing formative assessment for reading that changes the daily behaviors of teachers, students, and
											admins. <span className="text-ab-100">Learn More</span>
										</P>
									</Link>
								</div>
							</Col>
						</Row>

						<Link to="/intervention" className="hover:no-underline">
							<div className="w-full rounded-lg border-t-4 border-t-core-or bg-white shadow-lg">
								<div className="p-8">
									<H3 className="mb-3">Intervention</H3>
									<P>
										Whether your school uses ARC Core or another high-quality ELA program, there are always students who need additional support to become
										successful readers. <span className="text-ab-100">Learn More</span>
									</P>
								</div>
								<StaticImage src="../../static/images/home/toolkits-2.png" alt="arc core logo" />
							</div>
						</Link>
					</Col>
				</Row>
			</Section>
			<ImpactStats />
			<Section margin="mt-10 md:mt-20">
				<Row>
					<Col width="w-full md:w-4/5 xl:w-3/5" className="mx-auto">
						<FAQ items={faqs} />
					</Col>
				</Row>
			</Section>
			<Section margin="mt-20">
				<Row center>
					<Col width="w-full md:w-4/5 xl:w-3/5">
						<DigitalSamplesCTA onClick={goToCta} />
					</Col>
				</Row>
			</Section>
			<Section margin="mt-32">
				<Row>
					<Col>
						<div className="mb-6 text-center">
							<div className="mx-auto mb-2 h-3 w-40 bg-ab-100"></div>
							<H2>Research & Resources</H2>
						</div>
					</Col>
					<Row>
						<Col width="w-full lg:w-1/3" gutter="lg" className="mb-6 lg:mb-0">
							<Link to="news/test-scores-data-dive" className="hover:no-underline">
								<div className="rounded-lg border">
									<div className="p-3">
										<div className="flex h-56 items-center justify-center rounded-lg bg-gradient-to-r from-blue-200 to-purple-200">
											<span className="block text-center text-lg font-semibold text-gray-800">
												District Sees <br /> <span className="text-2xl">Results</span>
											</span>
										</div>
									</div>
									<div className="px-6 pb-6 pt-3">
										<P size="sm" shade="darker">
											This spring’s standardized test results showed the Lincoln Parish School District was among the top 10 in ...
										</P>
									</div>
								</div>
							</Link>
						</Col>
						<Col width="w-full lg:w-1/3" gutter="lg" className="mb-6 lg:mb-0">
							<Link to="/news/update-the-irla-predicts-reading-proficiency-on-standardized-tests/" className="hover:no-underline">
								<div className="rounded-lg border">
									<div className="p-3">
										<div className="flex h-56 items-center justify-center rounded-lg bg-gradient-to-r from-purple-200 to-red-200">
											<span className="block text-center text-lg font-semibold text-gray-800">
												The IRLA Predicts <br />
												<span className="text-2xl">Reading Proficiency</span> <br />
												on Standardized Tests
											</span>
										</div>
									</div>
									<div className="px-6 pb-6 pt-3">
										<P size="sm" shade="darker">
											New research continues to show the validity of the Independent Reading Level Assessment (IRLA). The evidence...
										</P>
									</div>
								</div>
							</Link>
						</Col>
						<Col width="w-full lg:w-1/3" gutter="lg">
							<Link to="/news/arc-core-r-rated-level-1-strong-in-evidence-for-essa-initiative-by-johns-hopkins-university/" className="hover:no-underline">
								<div className="rounded-lg border">
									<div className="p-3">
										<div className="flex h-56 items-center justify-center rounded-lg bg-gradient-to-r from-red-200 to-orange-200">
											<span className="block text-center text-lg font-semibold text-gray-800">
												ARC Core Rated <br />
												ESSA Level 1
												<br />
												<span className="text-2xl">"Strong"</span>
											</span>
										</div>
									</div>
									<div className="px-6 pb-6 pt-3">
										<P size="sm" shade="darker">
											ARC Core earned the highest rating as a Level 1 program demonstrating “Strong Evidence” of efficacy under...
										</P>
									</div>
								</div>
							</Link>
						</Col>
					</Row>
				</Row>
			</Section>
			<Section margin="mt-20 md:mt-40">
				<Row middle>
					<Col width="w-full md:w-1/2" className="hidden pr-4 sm:block md:pr-20">
						<ReviewBadges2 />
					</Col>
					<Col width="w-full md:w-1/2" className="mb-6 text-center sm:text-left md:mb-0">
						<div className="mx-auto mb-2 h-3 w-40 bg-ab-100 sm:mx-0"></div>
						<H2 className="mb-4">Backed by Independent Reviews & Results</H2>
						<P className="mb-8">
							Our instructional materials underwent a thorough review process by a national team of experts from EdReports and Rivet Education. ARC Core is backed by
							research, additional independent state reviews, and implementation results across the country.
						</P>
						<div className="mb-6 block sm:hidden">
							<ReviewBadges2 />
						</div>
						<div className="flex justify-center sm:justify-start">
							<Link to="/state-reviews">
								<Button size="xl">See State Reviews</Button>
							</Link>
						</div>
					</Col>
				</Row>
			</Section>
			<JoinOurWebinars />
			<Section margin="mt-20 md:mt-32">
				<Row middle overflow={false}>
					<Col width="w-11/12 sm:w-3/5 xl:w-2/5" className="relative z-0 mx-auto mb-8">
						<GetStartedCTA pageName="Home Page" />
						<CTAGrid />
						<span className="absolute -top-10 -right-6 z-[-1] hidden sm:inline">
							<svg width="100" height="100" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path fillRule="evenodd" clipRule="evenodd" d="M0 100C0 44.7715 0 0 0 0C55.2285 0 100 44.7715 100 100C100 100 100 100 0 100Z" fill="#0271BC"></path>
							</svg>
						</span>
					</Col>
				</Row>
			</Section>
			<Modal wide title="ARC Core Overview" isOpen={modalOpen} handleClose={() => setModalOpen(false)}>
				<OverlayVideo title="ARC Core Overview" noOverlay />
			</Modal>
		</Layout>
	)
}
